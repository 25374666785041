body {
  margin: 0;
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background: linear-gradient(
      to left,
      #ffd23f 0,
      #ffd23f 33.3%,
      #ee4266 33.3%,
      #ee4266 66.6%,
      #540d6e 66.6%,
      #540d6e
    )
    no-repeat;
  background-size: 100% 5px;
  margin-top: 5px;
}

.dashboard-emoji {
  font-size: 1.2em;
}

.jumbotron {
  background-color: #eee;
}

.person:not(:last-child) {
  border-bottom: 1px solid #ccc;
  padding: 2px 0 2px 0;
}

.person:hover {
  background-color: #efefef !important;
}

.setWarning {
  display: none;
}

.department:hover .setWarning {
  display: inline-block;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  content: "";
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(#818078),
    to(transparent)
  );
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

.very-stale.working {
  display: none;
}

.maybe-stale.working {
  opacity: 0.5;
}

.price-box {
  border-radius: 10px;
  color: #fff;
}

.free-tier-pricing {
  background-color: #540d6e;
}

.standard-tier-pricing {
  background-color: #ee4266;
}

.plus-tier-pricing {
  background-color: #ffd23f;
}

@media (min-width: 768px) {
  .liveDashboard {
    font-size: 1.2em;
  }
}

@media (min-width: 992px) {
  .liveDashboard {
    font-size: 1.6em;
  }
}
#your-status {
  position: relative;
  top: -6px;
}

#status-options {
  display: none;
}

#status-overview {
  z-index: 99999;
}

#status-overview:hover ~ #status-options,
#status-options:hover {
  display: block;
  position: absolute;
  padding-top: 5px;
  width: 500px;
  z-index: 9999;
  top: 25px;
  border-radius: 10px;
  border: 1px solid #efefef;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
